import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const IconsPage = () => (
    <Layout page="icons-page">
      <Seo title="ICONS"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>ICON</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block" id="movie">
            <h3 className="sub-title"><span>アイコンについて</span></h3>
          </div>
          <div className="content-block">
            アイコン一覧
          </div>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>アイコン一覧</li>
        </ul>
      </section>
    </Layout>
);

export default IconsPage;
